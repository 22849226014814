import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"

function Series() {
    const [seriesPage, setSeriesPage] = useState([])

    useEffect(() => {
        async function loadingData() {
            const rawResponse = await fetch('https://series.sebastienk.workers.dev/')

            const response = await rawResponse.json();
            const rawResponse2 = await fetch('https://series2.sebastienk.workers.dev/')

            const response2 = await rawResponse2.json();
            const rawResponse3 = await fetch('https://series3.sebastienk.workers.dev/')

            const response3 = await rawResponse3.json();
            const mergeResponse = [...response.results, ...response2.results, ...response3.results]

            const result = mergeResponse.filter(el => el.overview.length > 2)
            const finalResult = result.filter(el => el.vote_average > 5)
            setSeriesPage(finalResult)


        }

        loadingData()

    }, []);

    const seriesList = seriesPage.map((serie, i) => {
        let description = serie.overview
        if (description.length > 150) {
            description = description.slice(0, 150) + '...'
        }

        const seriesImg = "https://image.tmdb.org/t/p/w500" + serie.backdrop_path

        //window.innerWidth for desktop and screen.width for devices
        let deviceWidth
        if (window.innerWidth > 600) deviceWidth = "300px"
        else deviceWidth = "46vw"
        // removing width="300px" height="169px" need to do responsive img or nothing
        return (< div key={i} style={{ display: "flex", flexDirection: "column", height: "400px", backgroundColor: "rgb(235,235,235)", borderRadius: "10px", marginBottom: "10px", marginLeft: "5px", marginRight: "5px", width: deviceWidth }}>
            <img style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, padding: 1 }} src={seriesImg} alt={serie.name} />
            <h3 style={{ marginLeft: "10px", marginBottom: "10px", marginRight: "10px" }}>{serie.name}</h3>
            <p style={{ marginLeft: "10px", marginBottom: "10px" }}>Note : {serie.vote_average}/10</p>
            <p style={{ marginLeft: "10px", marginBottom: "10px", marginRight: "10px" }}>{description}</p>
        </div >)

    })
    return (
        <Layout>
            <Seo title="Series" />
            <h2 style={titleStyle}>Les Series du moment</h2>
            <div style={mainStyle}>
                {seriesList}
            </div>
        </Layout>
    )
}

const titleStyle = { textAlign: "center", color: "rgb(235,235,235)" }
const mainStyle = { display: "flex", width: "98vw", flexWrap: "wrap", justifyContent: "center" }

export default Series